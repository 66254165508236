import React from 'react';
import './ResultsPage.css'; // Import CSS file for styling

const ResultsPage = ({ results }) => {
  return (
    <div className="results-container">
      <table>
        <thead>
          <tr>
            <th>Text</th>
            <th>Matches</th>
            <th>Match Count</th>
            <th>Suggested Change</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result, index) => (
            <tr key={index}>
              <td>{result.text}</td>
              <td>{result.matches}</td>
              <td>{result.match_count}</td>
              <td>{result.suggested_change}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResultsPage;